//import 'js-cookie';

;
(function (jQuery) {
    var getUnixTimeMilliseconds = function () {
        return (new Date()).getTime();
    };
    window.getUnixTimeMilliseconds = getUnixTimeMilliseconds; // make "public"

    var preInitStarted = getUnixTimeMilliseconds();
    var waitedBeforeInit = 0;
    var deferTimeout = 10;
    var maxWaitBeforeInit = 3000; // milliseconds since preInitStart; just in case JS appears broken and we need to continue without dependencies

    var urlAthos = 'https://fahrzeuge.sbazv.de/WasteManagementSuedbrandenburg/WasteManagementXMLServiceServlet';

    var enablePeriodicRerenderFixedElements = false; // intermediate workaround for SBAZVWEB-260

    // configuration for jQuery Smooth Scroll
    // see: https://github.com/kswedberg/jquery-smooth-scroll/tree/7c327265673e06234f7ae740cbcf3aaf984ffed3
    var jScrollElement = null; // will be set upon initialization
    var smoothScrollDefaultOptions = {
        'easing': 'swing',
        'speed': 500,
        'beforeScroll': function (options) {
            var shouldRepeat = options['forceRepeat'] || false;

            // open accordion item if linked to an item directly or any element
            // beneath
            var jScrollTarget = findElementByScrollTargetDefinition(options['scrollTarget']);
            var accordionOpens = openAccordionForElementIfNeeded(jScrollTarget);

            // we may end up in a totally wrong position if an accordion is
            // being opened, so we need to repeat
            shouldRepeat |= accordionOpens;

            // if required, repeat scrolling after a short delay
            if (shouldRepeat) {
                var copiedOptions = jQuery.extend({}, options, {
                    'forceRepeat': false // don't repeating endlessly
                });
                window.setTimeout(function () {
                    jQuery.smoothScroll(copiedOptions);
                }, 500);
            }

            // top bar changes height due to responsive layout and thus needs
            // to be re-calculated before every scroll
            options['offset'] = -getTopBarHeight();
        },
        'afterScroll': function () {
            // intermediate workaround for SBAZVWEB-260
            enablePeriodicRerenderFixedElements = true;
            rerenderFixedElements();
        }
    };

    /**
     * Attempts to log the given object on JS console, if available.
     * @param mixed s object to log; usually a message string but could be an object as well
     */
    function log(s) {
        if ((typeof (console) != 'undefined') && (typeof (console.log) != 'undefined')) {
            console.log(s);
        }
    }

    /**
     * Attempts to log the given object as a warning on JS console. Resorts to
     * regular logging if console.warn is unavailable.
     * @param mixed s object to log; usually a message string but could be an object as well
     */
    function logWarning(s) {
        if ((typeof (console) != 'undefined') && (typeof (console.warn) != 'undefined')) {
            console.warn(s);
        } else {
            log(s);
        }
    }

    function myParseInt(s) {
        i = parseInt(s);

        // required for MSIE...
        if (isNaN(i)) {
            i = 0;
        }

        return i;
    }

    window.myParseInt = myParseInt;

    function getBaseUrl() {
        return window.location.href.match(/^.*\//);
    }

    ////Drilldown
    // Foundation.Drilldown.defaults.backButton = '<li class="js-drilldown-back"><a>Zur&uuml;ck</a></li>';
    // changed in foundation.drilldown.js line 93 to avoid void space in layout.

    //Magellan
    //Scroll Offset Threshold
    // Foundation.Magellan.defaults.threshold = '140';

    function initFoundation() {
        // Start
        jQuery(document).foundation();

        if (jQuery('#modal-element').length) {
            if (Cookies.get('showed_modal') !== "true" || jQuery('.rootline-page-1-current').length >= 1) {

                jQuery("#modal-element").foundation("open");
                Cookies.set('showed_modal', 'true', {expires: 1})
            }
        }
    }

    function initFlyOutMenu() {
        var jFlyoutMenus = jQuery('.menu-flyout');

        var isHomepage = jQuery('body').hasClass('homepage');
        if (!isHomepage) {
            // show on all regular pages after DOM is complete
            jFlyoutMenus.show();
        } else {
            // hide flyout menu on startpage and show on scroll
            jFlyoutMenus.hide();
            jQuery('div.off-canvas-content').scroll(function () {
                var y = jQuery(this).scrollTop();
                if (y > 900) {
                    jFlyoutMenus.fadeIn();
                } else {
                    jFlyoutMenus.fadeOut();
                }
            });
        }

        // Initially hide submenu entries
        jFlyoutMenus.find('li:not(:first-child)').hide();

        // Show submenu entries on parent hover
        jFlyoutMenus.find('ul').hover(function () {
            jQuery(this).find('li:not(:first-child)').show();
            jQuery(this).css('left', '17rem');
        }, function () {
            jQuery(this).css('left', '0');
            jQuery(this).find('li:not(:first-child)').hide();
        });
    }

    function initScrollToTop() {
        var jCanvas = jQuery('div.off-canvas-content');
        var previousStateShown;

        var updateState = function () {
            var scrollPosition = jCanvas.scrollTop();
            var shouldBeShown = scrollPosition > 100;

            //log(String(scrollPosition)+' '+String(shouldBeShown)+' '+previousStateShown); // DEBUG

            // no update needed if nothing changed
            // (avoid repeated "flicker")
            if ((typeof (previousStateShown) != 'undefined') && (previousStateShown == shouldBeShown)) {
                return;
            }

            var jScrollToTop = jQuery('.scrollToTop');
            jScrollToTop.stop().clearQueue();
            jScrollToTop.data('shouldBeShown', shouldBeShown); // for SBAZVWEB-260 workaround

            if (shouldBeShown) {
                jScrollToTop.fadeIn();
            } else {
                jScrollToTop.fadeOut();
            }

            previousStateShown = shouldBeShown;
        };

        jCanvas.scroll(updateState);
    }

    function initContactBoxes() {
        // Change Contactbox Image on Hover
        jQuery('.linkbox a').hover(function () {
            jQuery(this).find('img').attr("src", function (index, attr) {
                return attr.replace("grau.svg", "farbig.svg");
            });
        }, function () {
            jQuery(this).find('img').attr("src", function (index, attr) {
                return attr.replace("farbig.svg", "grau.svg");
            });
        });
    }

    function initDataTables() {

        // start datatables Vertriebsstellen
        // jQuery('.table-vertrieb').each(function () {
        // var jTable = jQuery(this);
        // jTable.DataTable();
        // });

        // init datatable garbage
        var garbageTable = jQuery('table.garbage-table').DataTable({
            language: {
                search: "Suchen",
                lengthMenu: "Einträge anzeigen: _MENU_",
                paginate: {
                    next: "Vor",
                    previous: "Zurück"
                },
                info: "Zeige _TOTAL_ Einträge",
                infoFiltered: "gefiltert von insgesamt _MAX_ Einträgen",
                zeroRecords: "Der eingegebene Begriff konnte nicht gefunden werden",
                infoEmpty: "Zeige 0 Einträge"
            },

            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, "Alle anzeigen"]],
            dom: '<"toolbar-garbage"fl>tp'
        });

        // start datatables Glascontainer
        var tableGlasscontainer = jQuery('table#table-glasscontainer').DataTable({
            // hide column0, hide sort icons
            "aoColumnDefs": [
                {
                    "aTargets": [1],
                    "bSortable": false

                },
                {
                    "targets": [0],
                    "visible": false
                }
            ],
            // order entries via name
            order: [[2, "asc"], [3, "asc"]],
            // show entries
            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, "Alle anzeigen"]],
            // localization
            language: {
                search: "Such nach:",
                info: "Zeige Einträge _START_ bis _END_ von _TOTAL_ Einträgen",
                lengthMenu: "Einträge anzeigen: _MENU_",
                paginate: {
                    next: "Vor",
                    previous: "Zurück"
                }
            },
            // add a custom toolbar
            "dom": '<"toolbar-glasscontainer">lfrtp',
            // create custom fields
            initComplete: function () {

                /**
                 * https://datatables.net/examples/api/multi_filter_select.html
                 */

                var tableName = "glasscontainer";

                this.api().columns().every(function (currentValue, index, arr) {
                    // Area
                    if (currentValue == 0) {
                        var column = this;
                        initializeAreaRadio(column, tableName);
                    }
                    // Location
                    if (currentValue == 2) {
                        initializeCitySelect(this, tableName);
                    }
                });
            }
        });

        // start datatables Altkleidercontainer
        var tableAltkleider = jQuery('table#table-altkleider').DataTable({
            // hide column0, hide sort icons
            "aoColumnDefs": [
                {
                    "aTargets": [1],
                    "bSortable": false

                },
                {
                    "targets": [0],
                    "visible": false
                    // "searchable": false,
                }
            ],
            // order entries via name
            order: [[2, "asc"], [3, "asc"]],
            // show entries
            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, "Alle anzeigen"]],
            // localization
            language: {
                search: "Such nach:",
                info: "Zeige Einträge _START_ bis _END_ von _TOTAL_ Einträgen",
                lengthMenu: "Einträge anzeigen: _MENU_",
                paginate: {
                    next: "Vor",
                    previous: "Zurück"
                }
            },
            // add a custom toolbar
            "dom": '<"toolbar-altkleider">lfrtp',
            // create custom fields
            initComplete: function () {

                /**
                 * https://datatables.net/examples/api/multi_filter_select.html
                 */

                var tableName = "altkleider";

                this.api().columns().every(function (currentValue, index, arr) {
                    // Area
                    if (currentValue == 0) {
                        var column = this;
                        initializeAreaRadio(column, tableName);
                    }
                    // Location
                    if (currentValue == 2) {
                        initializeCitySelect(this, tableName);
                    }
                });
            }
        });

        var tableVertrieb = jQuery('table#table-vertrieb').DataTable({
            // hide column0, hide sort icons
            aoColumnDefs: [
                {
                    "aTargets": [1, 2, 3],
                    "bSortable": false

                },
                {
                    "targets": [0],
                    "visible": false
                    // "searchable": false,
                }
            ],
            // order entries via name
            order: [[4, "asc"], [5, "asc"]],
            // show entries
            lengthMenu: [[5, 10, 20, -1], [5, 10, 20, "Alle anzeigen"]],
            // localization
            language: {
                search: "Such nach:",
                info: "Zeige Einträge _START_ bis _END_ von _TOTAL_ Einträgen",
                lengthMenu: "Einträge anzeigen: _MENU_",
                paginate: {
                    next: "Vor",
                    previous: "Zurück"
                }
            },
            // add a custom toolbar
            dom: '<"toolbar-vertrieb">lfrtip',
            // hide info field
            info: false,
            // create custom fields
            initComplete: function () {

                /**
                 * https://datatables.net/examples/api/multi_filter_select.html
                 */
                var tableName = "vertrieb";

                this.api().columns().every(function (currentValue, index, arr) {
                    // Area
                    if (currentValue == 0) {
                        var column = this;
                        initializeAreaRadio(column, tableName);
                    }
                    // Location
                    if (currentValue == 4) {
                        initializeCitySelect(this, tableName);
                    }
                });
            }
        });

        function initializeAreaRadio(theColumn, tableName) {
            var column = theColumn;
            var radio = jQuery('<radiogroup class="table-' + tableName + '_radio tab-4 columns"></radiogroup>')
                .appendTo(jQuery("div.toolbar-" + tableName))
                .on('change', function () {
                    var jRadioGroup = jQuery(this);
                    var val = jRadioGroup.find('input:radio:checked').next('label:first').html();
                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                    reinitializeCitySelect(val, tableName);

                    // select "show all" to avoid displaying an empty list
                    jQuery('select.table-' + tableName + '_select').val('').change();
                });

            column.data().unique().sort().each(function (d, j) {
                radio.append('<div class="table-radio-container"><input type="radio" name="' + tableName + '-landkreis-radio" value="' + j + '" id="' + tableName + '-radio-' + d + '"><label for="' + tableName + '-radio-' + d + '">' + d + '</label></div>');
            });
        }

        function initializeCitySelect(theColumn, tableName) {
            var column = theColumn;
            var select = jQuery('<select class="table-' + tableName + '_select" size="3"><option value="" selected>Alle Orte</option></select>')
                .appendTo(jQuery('div.toolbar-' + tableName))
                .on('change', function () {
                    var val = jQuery.fn.dataTable.util.escapeRegex(jQuery(this).val());
                    column.search(val ? '^' + val + '$' : '', true, false).draw();
                });
            column.data().unique().sort().each(function (d, j) {
                select.append('<option value="' + d + '">' + d + '</option>');
            });

            jQuery(".table-" + tableName + "_select").wrap("<div class='select-wrap tab-4 columns'></div>");
        }

        function reinitializeCitySelect(actualArea, tableName) {

            // set the area and get all cities from option list
            var area = actualArea;
            var options = jQuery('.table-' + tableName + '_select > option');

            // first we remove all the city entries in the area select box
            for (var i = options.length - 1; i > 0; i = i - 1) {
                options[i].remove();
            }

            // second we iterate through the complete table (should do) and
            // compare the newly selected area with the city entries, set up
            // a new array, sort it and append the new options

            var cities = [];
            var uniqueCities = [];

            var table = jQuery('table#table-' + tableName).DataTable();
            table.rows().every(function (rowIdx, tableLoop, rowLoop) {
                var data = this.data();
                if (area === data[0]) {
                    if (tableName == "vertrieb") {
                        cities.push(data[4]);
                    } else {
                        cities.push(data[2]);
                    }
                }
            });

            cities.sort();
            jQuery.each(cities, function (index, element) {
                if (jQuery.inArray(element, uniqueCities) === -1)
                    uniqueCities.push(element);
            });

            jQuery.each(uniqueCities, function (index, value) {
                // console.log(index+":"+value);
                jQuery('<option value="' + value + '">' + value + '</option>').appendTo('select.table-' + tableName + '_select');
            });

        }

        // Add custom pagination
        // this id is created automatically by dataTables
        jQuery("#table-vertrieb_wrapper .dataTables_length").addClass('tab-4 columns');
        jQuery(".toolbar-vertrieb").append(jQuery("#table-vertrieb_wrapper .dataTables_length"));
        jQuery("#table-altkleider_wrapper .dataTables_length").addClass('tab-4 columns');
        jQuery(".toolbar-altkleider").append(jQuery("#table-altkleider_wrapper .dataTables_length"));
        jQuery("#table-glasscontainer_wrapper .dataTables_length").addClass('tab-4 columns');
        jQuery(".toolbar-glasscontainer").append(jQuery("#table-glasscontainer_wrapper .dataTables_length"));

        // DataTable: Change Size of Selectbox only on Mobile View
        var checkSize = function () {
            if (jQuery(".col-icon").css("float") == "right") {
                if (jQuery("#table-vertrieb_select").attr("size") == 3) {
                    jQuery("#table-vertrieb_select").attr("size", "1");
                }
            } else if (jQuery("#table-vertrieb_select").attr("size") == 1) {
                jQuery("#table-vertrieb_select").attr("size", "3");
            } else {
                if (jQuery("#table-vertrieb_select").attr("size") == 1) {
                    jQuery("#table-vertrieb_select").attr("size", "3");
                }
            }
        };
        checkSize();
        // FIXME: to consuming, refer to foundation possibilites
        jQuery(window).resize(checkSize);
    }

    function initICheck() {
        // Checkbox and Radio
        jQuery('input[type="checkbox"], input[type="radio"]').iCheck({
            checkboxClass: 'icheckbox_square-blue',
            radioClass: 'iradio_square-blue'
        });
    }

    function initAccordions() {
        // merge all adjacent accordions (grouping FCEs)
        var jAccordionContainers = jQuery('.merge-adjacent-accordions');
        var accordionContainers = jAccordionContainers.toArray();
        for (var i = accordionContainers.length; i >= 0; i--) {
            var jCurrentAccordionContainer = jQuery(accordionContainers[i]);
            var jPrevElem = jCurrentAccordionContainer.prev();

            var shouldMergePreviousElement = jPrevElem.hasClass('merge-adjacent-accordions');
            if (shouldMergePreviousElement) {
                var jPreviousAccordionList = jPrevElem.find('ul.accordion');
                var jCurrentAccordionItems = jCurrentAccordionContainer.find('li.accordion-item');
                jCurrentAccordionItems.appendTo(jPreviousAccordionList);
                jCurrentAccordionContainer.remove();
            }
        }

        // periodically check and record which accordion items are active (open)
        // so we can avoid opening them again when clicked, otherwise they
        // cannot be closed
        var accordionItemStateOpen = {};
        var autoIdCounter = 0;
        var autoIdPrefix = 'accordion-watcher-'
        jQuery(document).everyTime(500, function () {
            jQuery('.accordion-item').each(function () {
                var jAccordionItem = jQuery(this);
                var id = jAccordionItem.attr('id');

                // auto-assign an ID to track that accordino item if it has no
                // ID yet
                if (!id) {
                    id = autoIdPrefix + String(autoIdCounter++);
                    jAccordionItem.attr('id', id);
                }

                // update state
                accordionItemStateOpen[String(id)] = jAccordionItem.hasClass('is-active');
            });
        });

        // scroll to accordion if clicked
        jQuery(document).on('click', '.accordion-title', function () {
            var jAccordionTitle = jQuery(this);
            var jAccordionItem = findAccordionItemForElement(jAccordionTitle);
            var accordionItemId = jAccordionItem.attr('id');

            // don't re-open accordion items which were already open, else they
            // cannot be closed
            var isActive = !!accordionItemId && !!accordionItemStateOpen[String(accordionItemId)];
            if (!isActive) {
                scrollToElement(jAccordionItem, true);
            }
        });
    }

    // this function is nice and we could have used it if we would be sure
    // that every content might be a list. But be sure: it won't!

    function reformatLists() {
        var jLists = jQuery('.reformat-as-two-column-list ul');

        // remove marker class in case this initialization re-runs
        // FIXME: what is "parentsAll()"?
        //jLists.parentsAll(.reformat-as-two-column-list).removeClass('reformat-as-two-column-list');

        jLists.each(function () {
            var jList = jQuery(this);

            var jListItems = jList.find('li');

            // don't create a second list if we have nothing to insert
            var jListElementsCount = jListItems.length;
            if (jListElementsCount < 2) {
                return;
            }

            var jSecondList = jQuery('<ul></ul>').insertAfter(jList);

            jListItems.each(function (index) {
                if (index >= jListElementsCount / 2) {
                    jQuery(this).appendTo(jSecondList);
                }
            });
        });
    }

    /**
     * Calculates the current height of top (menu/title) bar.
     * @returns {int} total height of top bar in pixels (including padding and border)
     */
    function getTopBarHeight() {
        var jTopMenu = jQuery('.off-canvas-content>.menu-wrapper>.row.menu');
        var totalHeight = jTopMenu.height();
        totalHeight += myParseInt(jTopMenu.css('padding-top'));
        totalHeight += myParseInt(jTopMenu.css('padding-bottom'));
        totalHeight += myParseInt(jTopMenu.css('border-top-width'));
        totalHeight += myParseInt(jTopMenu.css('border-bottom-width'));

        return totalHeight;
    }

    function findAccordionContainerForElement(jElem) {
        return jElem.hasClass('accordion') ? jElem : jElem.parents('.accordion').first();
    }

    function findAccordionItemForElement(jElem) {
        return jElem.hasClass('accordion-item') ? jElem : jElem.parents('.accordion-item').first();
    }

    /**
     * Checks if the given element is part of an accordion and opens the item
     * if not already opened. In case the element is not part of an accordion or
     * the accordion item has already been opened, this function will return
     * false.
     * @param {jQuery} jElem element to check and open accordion for
     * @returns {Boolean} Did we need to open the accordion item?
     */
    function openAccordionForElementIfNeeded(jElem) {
        var jAccordion = findAccordionContainerForElement(jElem);
        var jAccordionItem = findAccordionItemForElement(jElem);

        if ((jAccordion.length == 0) || (jAccordionItem.length == 0)) {
            return false;
        }

        // no need to open accordion items that are already indicated as being
        // open
        if (jAccordionItem.hasClass('is-active')) {
            return false;
        }

        jAccordion.foundation('down', jAccordionItem.find('>.accordion-content'));

        return true;
    }

    function findElementByScrollTargetDefinition(scrollTarget) {
        // return empty jQuery object on error
        if ((typeof (scrollTarget) == 'undefined') || (scrollTarget === null)) {
            return jQuery();
        }

        // return input if we got a jQuery object as scroll target
        if (typeof (scrollTarget.jquery) != 'undefined') {
            return scrollTarget;
        }

        // return empty jQuery object if scroll target is not in CSS ID
        // reference syntax
        var isIdSyntax = scrollTarget.match(/^#[a-z0-9_\-]+$/i);
        if (!isIdSyntax) {
            return jQuery();
        }

        // find reference
        return jQuery(scrollTarget);
    }

    function scrollToElement(jScrollTarget, forceRepeat) {
        jQuery.smoothScroll(jQuery.extend(smoothScrollDefaultOptions, {
            'scrollTarget': jScrollTarget,
            'forceRepeat': forceRepeat || false
        }));
    }

    function initSmoothScroll() {
        // FIXME: scrolling is affected by SBAZVWEB-260
        jScrollElement = jQuery('body>.off-canvas-wrapper>.off-canvas-wrapper-inner>.off-canvas-content');
        smoothScrollDefaultOptions['scrollElement'] = jScrollElement;

        // animate on clicks
        jQuery('a').smoothScroll(smoothScrollDefaultOptions);

        // animate if page was entered using a jump url which is a valid object
        var jumpPath = window.location.hash;
        if ((typeof (jumpPath) != 'undefined') && (jumpPath != '')) {
            var jJumpTarget = findElementByScrollTargetDefinition(jumpPath);

            if (jJumpTarget.length == 0) {
                logWarning('unable to find jump target: "' + String(jumpPath) + '"');
            } else {
                scrollToElement(jJumpTarget);
            }
        }

        // scroll to top of ATHOS forms on page change
        jQuery(document).on('pageChange', '.formifier-terminal', function (e, serverResponseXML) {
            var shouldScrollToTopOfTerminal = false;

            var jFormifierTerminal = jQuery(this);
            var jServerResponseXML = jQuery(serverResponseXML);
            var jSessionData = jServerResponseXML.find('>XML>SERVLET>SESSIONDATA');

            // scroll to top if we are showing error messages
            var jErrors = jServerResponseXML.find('>XML>SERVLET>INFORMATIONS>');
            var hasErrors = (jErrors.length > 0);
            shouldScrollToTopOfTerminal |= hasErrors;

            // check if page name has changed since last check
            var newPageName = jSessionData.find('>PAGENAME').text();
            var previouslyDisplayPageName = jFormifierTerminal.data('previouslyDisplayPageName') || null;
            var displayedPreviousPage = (previouslyDisplayPageName != null);
            var isNewPage = (newPageName != previouslyDisplayPageName);

            // we should scroll to top when moving between pages but not if this
            // is the initial page view
            shouldScrollToTopOfTerminal |= (isNewPage && displayedPreviousPage);

            // store new page name for next check
            jFormifierTerminal.data('previouslyDisplayPageName', newPageName);

            // scroll if we decided to do so
            if (shouldScrollToTopOfTerminal) {
                scrollToElement(jFormifierTerminal);
            }
        });
    }

    function initCloseOffCanvasMenu() {
        jQuery(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
            if (Foundation.MediaQuery.atLeast('large')) {
                jQuery('.close-button').click();
            }
        });
    }

    function initMap() {
        jQuery('.sbazv-map').each(function () {
            var jMapContainer = jQuery(this);

            var coordsRecyclingNiederlehme = [52.31776, 13.67357];
            var coordsRecyclingLuckenwalde = [52.09630, 13.14353];
            var coordsRecyclingLudwigsfelde = [52.29745, 13.27505];

            // instantiate map
            var mapContainer = jMapContainer[0];
            var map = L.map(mapContainer);

            // tile server
            var tilesOSM = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
            var attributionOSM = 'Map data &copy; <a href="http://www.openstreetmap.org/" target="_blank">OpenStreetMap</a> contributors (<a href="http://www.openstreetmap.org/copyright" target="_blank">Terms</a>)';
            var optionsOSM = {minZoom: 8, maxZoom: 16, attribution: attributionOSM};

            // add tile layer
            var layerOSM = L.tileLayer(tilesOSM, optionsOSM);
            map.addLayer(layerOSM);

            // coordinates
            var coords = [0, 0];

            if (jMapContainer.data('location') == 'luckenwalde') {
                coords = coordsRecyclingLuckenwalde;
            } else if (jMapContainer.data('location') == 'ludwigsfelde') {
                coords = coordsRecyclingLudwigsfelde;
            } else if (jMapContainer.data('location') == 'niederlehme') {
                coords = coordsRecyclingNiederlehme;
            }

            var coordsMarker = coords;

            // define marker icon
            var markerIcon = L.icon({
                'iconUrl': '/typo3conf/ext/templates_sbazv_main2016/Resources/Public/Media/Additions/leaflet-0.5.1/images/marker-icon@2x.png',
                'iconSize': [25, 40],
                'iconAnchor': [12, 40]
                //'popupAnchor': [1, 0]
            });

            // set marker
            var markerOptions = {
                'icon': markerIcon
            };
            L.marker(coordsMarker, markerOptions).addTo(map);

            // center view on marker
            var zoomLevel = 16;
            map.setView(coordsMarker, zoomLevel);

            // FIXME: should only be called temporarily while accordion animation is running
            jMapContainer.everyTime('300ms', 'invalidateSize', function () {
                map.invalidateSize(false);
            });
        });
    }

    function initOrbitSlideCounter() {
        jQuery(document).on('slidechange.zf.orbit', '[data-gallery]', function () {
            var jOrbit = jQuery(this);

            var jSlides = jOrbit.find('.orbit-slide');
            var jActiveSlide = jSlides.filter('.is-active');
            var activeNumber = jSlides.index(jActiveSlide) + 1;
            jOrbit.find('.slide-number').text(activeNumber);
        });
    }

    function workaroundMainMenuResize() {
        // workaround for SBAZVWEB-254
        // Foundation removes CSS class submenu from some menu elements on
        // resize, so we re-add them
        jQuery(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
            if (Foundation.MediaQuery.atLeast('large')) {
                var jMainMenuDesktop = jQuery('.top-bar .top-bar-right .large-horizontal.vertical.menu.dropdown');
                var jMainLevelItem = jMainMenuDesktop.find('>li');
                var jSubMenuColumnItems = jMainLevelItem.find('>ul>li');
                var jSubMenuColumnLists = jSubMenuColumnItems.find('>div>ul');
                jSubMenuColumnLists.addClass('submenu');
            }
        });
    }

    function initTableMobile() {

        jQuery('table').each(function () {
            var jTable = jQuery(this);

            jTable.find('th').each(function () {
                var jColumn = jQuery(this);
                var jColumnText = jColumn.text();

                var index = jColumn.index();

                // replace title only if not defined yet
                var jTargets = jTable.find('td:nth-child(' + String(index + 1) + '):not([data-title])');
                jTargets.attr('data-title', jColumnText);
            });
        });
    }

    function initFormifier() {
        var jTerminal = jQuery('.formifier-terminal');

        var replaceWithErrorMessage = function (html) {
            jTerminal.html('<div class="callout alert"><p>' + html + '</p></div>');
        };

        // skip initialization if no terminal has been requested
        if (jTerminal.length == 0) {
            return;
        }

        // skip with error message if multiple terminals have been requested
        // on the same page (IDs of form elements would collide, so it's not
        // possible to have multiple forms on one page at the same time)
        if (jTerminal.length != 1) {
            replaceWithErrorMessage('Es kann nur jeweils ein ATHOS-Formular pro Seite eingebunden werden; bitte alle anderen Formulare entfernen.');
            return;
        }

        // show error if Formifier has not been loaded
        if (typeof (jTerminal.formifier) == 'undefined') {
            replaceWithErrorMessage('Das ATHOS-Terminal ist nicht eingebunden.');
            return;
        }

        // extract module name from CSS classes
        var re = /^(|.*\s)athos\-module\-(\S+)/;
        var m;
        var moduleName = null;
        if (m = re.exec(jTerminal.attr('class'))) {
            moduleName = m[2];
        } else {
            replaceWithErrorMessage('Der Modulname des ATHOS-Formulars wurde nicht korrekt &uuml;bergeben.');
            return;
        }

        // load module
        jTerminal.formifier({
            url: urlAthos,
            moduleName: moduleName
        });
    }

    function workaroundAccordionOrbit() {
        // Orbits (image sliders) inside an accordion container will receive a
        // height of 0 if they are initialized while the accordion is still
        // closed. This appears to be a frequent regression in Foundation.
        // We attempt to work around it by saving all original orbit HTML code
        // inside accordions _before_ Foundation is initialized. If an accordion
        // opens, we "destroy" (unregister & hide) the previously instantiated
        // orbit instance, restore the original HTML and reinitialize it.

        var originalTemplates = [];
        var dataNameId = 'workaroundAccordionOrbitId';

        jQuery('.accordion-item .orbit').each(function () {
            var jOrbit = jQuery(this);
            jOrbit.data(dataNameId, originalTemplates.length);
            originalTemplates.push(jOrbit.clone());
        });

        jQuery(document).on('down.zf.accordion', '.accordion', function () {
            var jAccordion = jQuery(this);
            var jAccordionItem = jAccordion.find('.accordion-item.is-active');

            var jOrbits = jAccordionItem.find('.orbit');
            jOrbits.each(function () {
                var jOrbit = jQuery(this);
                var workaroundId = jOrbit.data(dataNameId);
                if (workaroundId === null) {
                    logWarning('found orbit inside accordion without a workaround ID, unable to reinitialize');
                    return;
                }

                log('reinitializing orbit inside accordion, workaround ID ' + String(workaroundId));

                jOrbit.foundation('destroy');
                var jRestored = originalTemplates[workaroundId].clone();
                jRestored.data(dataNameId, workaroundId);
                jOrbit.replaceWith(jRestored);
                new Foundation.Orbit(jRestored, {});

                // Foundation may fail to read correct image heights; check all
                // slide and container height attributes and fix them if needed,
                // all slides should be fully visible.
                // The issue was reproduced with Firefox 50.0 on Windows 7 but
                // wasn't necessary e.g. on Chrome 54.
                var jContainer = jRestored.find('ul.orbit-container');
                var jSlides = jContainer.find('li.orbit-slide');
                var maxImageHeight = 0;

                jSlides.each(function () {
                    var jSlide = jQuery(this);
                    var imageHeight = myParseInt(jSlide.find('img').attr('height'));

                    // find greatest image height
                    if (imageHeight > maxImageHeight) {
                        maxImageHeight = imageHeight;
                    }

                    // slide may use wrong max-height setting, fix if needed
                    var slideMaxHeight = myParseInt(jSlide.css('max-height'));
                    if (slideMaxHeight < imageHeight) {
                        logWarning('Orbit slide required height fix (Foundation recognized ' + String(slideMaxHeight) + 'px but image height is ' + String(imageHeight) + 'px)');
                        jSlide.css('max-height', String(imageHeight) + 'px');
                    }
                });

                // fix container height if needed
                var containerHeight = myParseInt(jContainer.css('height'));
                if (containerHeight < maxImageHeight) {
                    logWarning('Orbit container required height fix (Foundation recognized ' + String(containerHeight) + 'px but maximum image height of slides is ' + String(maxImageHeight) + 'px)');
                    jContainer.css('height', String(maxImageHeight) + 'px');
                }
            });
        });
    }

    function rerenderFixedElements() {
        // should only be needed as an intermediate workaround for SBAZVWEB-260
        var jFixedElements = jQuery('.off-canvas-content .menu-wrapper, .off-canvas-content>a.scrollToTop, #menu-flyout');
        jFixedElements.hide(0, function () {
            var jFixedElement = jQuery(this);

            // elements need to be able to suppress showing elements that
            // shouldn't be shown
            var shouldBeShown = jFixedElement.data('shouldBeShown');
            if (typeof (shouldBeShown) == 'undefined') {
                shouldBeShown = true;
            }

            if (shouldBeShown) {
                jFixedElement.show();
            }
        });
    }

    function workaroundPeriodicRerenderFixedElements() {
        // should only be needed as an intermediate workaround for SBAZVWEB-260
        var periodicRerenderingWasPreviouslyEnabled = false;
        jQuery('body').everyTime(500, function () {
            if (enablePeriodicRerenderFixedElements) {
                if (!periodicRerenderingWasPreviouslyEnabled) {
                    logWarning('periodic re-rendering of fixed position elements has been enabled (workaround for SBAZVWEB-260)');
                    periodicRerenderingWasPreviouslyEnabled = true;

                    // trigger re-rendering on manual scroll and resize as well
                    jQuery('.off-canvas-content').on('scroll', rerenderFixedElements);
                    jQuery(window).on('resize', rerenderFixedElements);
                }

                rerenderFixedElements();
            }
        });
    }

    function addTopReferenceToSamePageAnchors() {
        var reStartsWithSlash = /^\//;
        var reProtocolDomain = /^(https?:\/\/[^\/]+)/;
        var reURLSplitParametersOrReference = /^([^\?#]*)([\?#].*|)/;
        var reURLSplitReference = /^([^#]*)([#].*|)/;

        var currentURL = window.location.href;

        // extract domain and protocol from current URL
        var protocolDomain = '';
        var m;
        if (m = reProtocolDomain.exec(currentURL)) {
            protocolDomain = m[1];
        } else {
            // unable to prefix without known domain
            logWarning('protocol and domain could not be extracted from current URL; unable to prefix anchors for same page');
            return;
        }

        // remove reference from current page URL
        if (m = reURLSplitReference.exec(currentURL)) {
            if (m[2] != '') {
                currentURL = m[1];
            }
        }

        //log('current: '+currentURL); // DEBUG

        // check all anchors for same page and add reference to top
        jQuery('a').each(function () {
            var jAnchor = jQuery(this);

            // skip anchors which ask not to have #top added
            if (jAnchor.hasClass('no-top')) {
                return;
            }

            var url = jAnchor.attr('href');

            if (reStartsWithSlash.exec(url)) {
                // complete URL with domain
                url = protocolDomain + url;
            } else if (!reProtocolDomain.exec(url)) {
                // not a valid page URL, skip
                return;
            }

            // check if URL contains parameters or references; skip if that's the case
            var m;
            if (m = reURLSplitParametersOrReference.exec(url)) {
                if (m[2] != '') {
                    return;
                }
            }

            var isSamePage = (url == currentURL);
            //log('checked '+url+', result: '+(isSamePage ? 'same' : 'different')); // DEBUG

            if (isSamePage) {
                var urlToTop = url + '#top';
                //log('  setting '+urlToTop); // DEBUG
                jAnchor.attr('href', urlToTop);
            }
        });
    }

    function getScrollOffset() {
        return {
            'top': myParseInt(jScrollElement.scrollTop()),
            'left': myParseInt(jScrollElement.scrollLeft())
        };
    }

    window.getScrollOffset = getScrollOffset;

    function injectTypeKitToIFrames() {
        var frameName = 'piwik-optout';

        var jTargetFrames = jQuery('iframe[name="' + frameName + '"]');
        if (jTargetFrames.length == 0) {
            return;
        }

        var jTargetFrame = jTargetFrames.first();

        // skip if no typekit ID is set on iframe
        var typekitId = jTargetFrame.data('typekitId');
        if (typeof (typekitId) == 'undefined') {
            return;
        }

        if (typeof (window.WebFont) == 'undefined') {
            logWarning('WebFontLoader unavailable, unable to inject to IFrame');
            return;
        }

        // FIXME: we need to do this once after each document.ready inside the
        //        iframe - how can we hook up a trigger? remove arbitrary delay
        //        if we found out how

        setTimeout(function () {
            log('injecting TypeKit webfonts to IFrame');

            window.WebFont.load({
                'context': frames[frameName],
                'typekit': {
                    'id': typekitId
                }
            });
        }, 2500);
    }

    function removeNoJsClass() {
        jQuery('.nojs').removeClass('nojs');
    }

    function initExtendedTracking() {
        //enable heart beat timer
        _paq.push(['enableHeartBeatTimer', 15]);
        //trackEvent(category, action, [name], [value])
        //_paq.push(['trackEvent', 'Documentary', 'Play', 'Thrive']);

        var pageTitle = jQuery(document).find("title").text().replace("SBAZV :: ", "");

        // Startseite
        // Klick auf Slider
        jQuery('.orbit-container a').on('click', function () {
            var jClickedTag;
            if (jQuery(this).hasClass('read-more')) {
                jClickedTag = jQuery(this).parent().find('a:first');
            } else {
                jClickedTag = jQuery(this);
            }

            var sliderCategory = jClickedTag.find('h4').text();
            var sliderInfo = jClickedTag.find('h3').text();
            var sliderTitle = sliderCategory + ' – ' + sliderInfo;
            _paq.push(['trackEvent', pageTitle, 'clicked', 'Slider: ' + sliderTitle]);
        });

        // Klick auf Entsorgungsformulare
        jQuery('.tile-service a').on('click', function () {
            var formTitle = jQuery.trim(jQuery(this).html());
            _paq.push(['trackEvent', pageTitle, 'clicked', 'Formular Schnellzugriff: ' + formTitle]);
        });

        // Klick auf News
        jQuery('.tile-news a').on('click', function () {
            var jClickedTag;

            if (jQuery(this).hasClass('read-more')) {
                jClickedTag = jQuery(this).parent().find('a:first');
            } else {
                jClickedTag = jQuery(this);
            }
            var newsCategory = jClickedTag.next('h4').text();
            var newsTitle = jClickedTag.next('h4').next('h3').text();
            _paq.push(['trackEvent', pageTitle, 'clicked', 'News: ' + newsCategory + ' – ' + newsTitle]);
        });

        // Klick auf Category
        jQuery('.tile-category a').on('click', function () {
            var infoCategory = jQuery(this).find('span').text();
            _paq.push(['trackEvent', pageTitle, 'clicked', 'Infobox: ' + infoCategory]);
        });

        // Klick auf FAQ
        jQuery('.tile-imagebox a').on('click', function () {
            var imageboxCategory = jQuery.trim(jQuery(this).find('p').text());
            _paq.push(['trackEvent', pageTitle, 'clicked', 'FAQ Box: ' + imageboxCategory]);
        });

        // Alle Seiten
        // Klick auf Adressleiste (mit Seite als Category!)
        jQuery('.linkbox a').on('click', function () {
            var linkboxCategory = jQuery(this).find('span.info').text();
            _paq.push(['trackEvent', pageTitle, 'clicked', 'Link Box: ' + linkboxCategory]);
        });

        // Öffnen und schließen von Tabs! (mit Seite als Category)
        jQuery('a.accordion-title').on('click', function () {
            var accordionTitle = jQuery(this).find('span.info').text();
            var accordionAction = jQuery(this).parent().hasClass('is-active') ? 'opened' : 'closed';
            _paq.push(['trackEvent', pageTitle, accordionAction, 'Accordion: ' + accordionTitle]);
        });
    }

    function workaroundAthosDisplay() {
        console.log('function workaroundAthosDisplay called');
        jQuery(document).ajaxComplete(function () {
            console.log('function ajaxComplete called');
            // remove 1cbm from selection box
            // jQuery('.athos-module-SperrmuellAnmelden').find('.formifier-radiolistitem').first().remove();
            // if this text appearSo, s, we set it bold
            jQuery("p:contains(die zum Sperrmüll gehören)").css({'font-weight': 'bold', 'color': '#e52600'});
            // if a button is called "Absenden" (case sensitive) we do prepend some text
            jQuery("button:contains(Absenden)").closest('div.formifier-buttons').prepend('<div class="small-12 columns"><p>Bitte überprüfen Sie Ihre Angaben. Falls Sie der Zusammenfassung zustimmen, drücken Sie auf <b>Absenden</b>. Nach dem Absenden erhalten Sie eine E-Mail zur Bestätigung Ihrer Anmeldung.</p></div>');
            //jQuery('div.formifier-buttons').clone().appendTo('div.formifier-form');
        });

    }

    /**
     * Initiate the consent by adding an EventListener on 'cookieConsent' which will
     * be fired on two occasions:
     * 1. clicking on save or save all in the modal dialog
     * 2. as long as the consent cookie ('cookie_consent' or named different via typoscript) exists
     *    as soon as the cookie_consent.js of the extension is loaded (default in footer).
     * So be aware this function is loaded *before* the event is fired, otherwise you won't get the proper
     * information on customer decisions as long as you are not implementing your own logic. But we're lazy, are we?
     */
    function initCookieConsent() {
        window.matomoLoaded = false;
        var matomoSrc = getMatomoSrc();

        window.addEventListener('cookieConsent', function (event) {
            var _paq = window._paq = window._paq || [];

            if (event.detail.hasOption('matomo')) {
                if (false === window.matomoLoaded) {
                    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
                    _paq.push(['trackPageView']);
                    _paq.push(['enableLinkTracking']);
                    _paq.push(['requireCookieConsent']);
                    (function () {
                        var u = matomoSrc;
                        _paq.push(['setTrackerUrl', u + 'matomo.php']);
                        _paq.push(['setSiteId', '1']);
                        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
                        g.type = 'text/javascript';
                        g.async = true;
                        g.src = u + 'matomo.js';
                        s.parentNode.insertBefore(g, s);
                    })();
                    console.log('matomo enabled');
                    _paq.push(['setCookieConsentGiven']);
                }
            } else {
                console.log('matomo disabled');
                _paq.push(['forgetCookieConsentGiven']);
                _paq.push(["disableCookies"]);
                _paq.push(["deleteCookies"]);
                eraseMatomoCookies();
            }
        });
    }

    /**
     * Hence Matomo is not 'deleting' its own cookies (even if not used) this might cause confusion on the side
     * of data protection officers. So we added a small function to erase all Matomo cookies.
     */
    function eraseMatomoCookies() {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf("=");
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (name.includes('_pk_')) {
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        }
    }

    /**
     * In order to make this script more customizable we introduced live and general dev environment.
     * Rule: if not live environment it will be handled with the dev instance of matomo. adapt to your own needs.
     * @returns {string}
     */
    function getMatomoSrc() {
        var myLocation = window.location;
        var matomoSrc = '//dev.t39.sbazv.glutrot.de/piwik/';
        console.log(myLocation.host);
        // if not dev than only use live!
        if (myLocation.host === 'www.sbazv.de') {
            matomoSrc = '//matomo.sbazv.de/';
        }
        return matomoSrc
    }

    function initMain() {

        var jBody = jQuery('body');

        // check if webfonts are still loading, defer if not ready yet
        waitedBeforeInit = getUnixTimeMilliseconds() - preInitStarted;
        if (jQuery('html').hasClass('wf-loading') && (waitedBeforeInit < maxWaitBeforeInit)) {
            setTimeout(initMain, deferTimeout);
            return;
        }

        log('webfonts deferred initialization by ' + String(waitedBeforeInit) + 'ms');

        initAccordions();
        workaroundAccordionOrbit();
        addTopReferenceToSamePageAnchors();
        initFoundation();
        initFlyOutMenu();
        initScrollToTop();
        initContactBoxes();
        initDataTables();
        initCloseOffCanvasMenu();
        initMap();
        initOrbitSlideCounter();
        initTableMobile();
        initFormifier();
        workaroundMainMenuResize();
        workaroundPeriodicRerenderFixedElements();
        initSmoothScroll();
        //injectTypeKitToIFrames();
        removeNoJsClass();
        initExtendedTracking();
        workaroundAthosDisplay();
    }

    jQuery(document).ready(initMain);

})(jQuery);


